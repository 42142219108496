.header {
  position: relative;
  z-index: 10000;
  width: 100%;
  height: 96px;
  padding: 0;
  border: none;
  border-spacing: 0;
  background-color: #f3f5f8;

  td {
    padding: 0;
  }
}
.header-container {
  width: 95%;
  max-width: 1425px;
  min-width: 1000px;
  padding: 0;
  margin: auto;
  border-spacing: 0;
  border: none;
  table-layout: fixed;

  td {
    padding: 0;
  }
}
.header.is-elite {
  background-color: #363a46;
  height: 46px;
  line-height: 46px;

  .header-container {
    table-layout: auto;
    height: 46px;
  }
}
.header .fv-container {
  display: table;
}
.header .table-row {
  display: table-row;
}
.header .pull-left {
  display: table-cell;
  width: 25%;
  max-width: 380px;
}
.header .pull-right {
  display: table-cell;
  width: 728px;
}
.header .logo {
  background: url('/img/logo_1.png') no-repeat;
  display: block;
  width: 246px;
  height: 39px;
}
@media (min--moz-device-pixel-ratio: 1.3),
  (-o-min-device-pixel-ratio: 2.6/2),
  (-webkit-min-device-pixel-ratio: 1.3),
  (min-device-pixel-ratio: 1.3),
  (min-resolution: 1.3dppx) {
  .header .logo {
    background-image: url('/img/logo_1_2x.png');
    background-size: 246px 39px;
  }
}
@media screen and (min-width: 1250px) {
  .header .logo {
    background: url('/img/logo_2.png') no-repeat;
    width: 335px;
  }
}
@media (min--moz-device-pixel-ratio: 1.3) and (min-width: 1250px),
  (-o-min-device-pixel-ratio: 2.6/2) and (min-width: 1250px),
  (-webkit-min-device-pixel-ratio: 1.3) and (min-width: 1250px),
  (min-device-pixel-ratio: 1.3) and (min-width: 1250px),
  (min-resolution: 1.3dppx) and (min-width: 1250px) {
  .header .logo {
    background-image: url('/img/logo_2_2x.png');
    background-size: 335px 39px;
  }
}
@media screen and (min-width: 1500px) {
  .header .logo {
    background: url('/img/logo_3.png') no-repeat;
    width: 405px;
  }
}
@media (min--moz-device-pixel-ratio: 1.3) and (min-width: 1500px),
  (-o-min-device-pixel-ratio: 2.6/2) and (min-width: 1500px),
  (-webkit-min-device-pixel-ratio: 1.3) and (min-width: 1500px),
  (min-device-pixel-ratio: 1.3) and (min-width: 1500px),
  (min-resolution: 1.3dppx) and (min-width: 1500px) {
  .header .logo {
    background-image: url('/img/logo_3_2x.png');
    background-size: 405px 39px;
  }
}
.header.is-elite .logo {
  background: url('/img/logo_elite.png') no-repeat;
  width: 160px;
  height: 32px;

  @media (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
    background-image: url('/img/logo_elite_2x.png');
    background-size: 160px 32px;
  }
}
.header .logo img {
  vertical-align: middle;
}
.header .search {
  display: inline-block;
  width: 100%;
  position: relative;
  font-family: Lato, Verdana, Arial, Tahoma;
  vertical-align: top;
  padding-top: 4px;
}
.header .search input {
  background: #fff;
  border: none;
  border-bottom: 1px solid #cfd0d3;
  border-radius: 0;
  line-height: 13px;
  font-family: Arial, sans-serif;
  font-size: 13px;
  color: #707990;
  outline: 0;
  width: 100%;
  height: 37px;
  padding: 5px 0 6px 14px;
}
.header .search input::-webkit-input-placeholder {
  color: #707990;
  line-height: 17px;
}
.header .search input:-moz-placeholder {
  color: #707990;
  line-height: 23px;
}
.header .search input::-moz-placeholder {
  color: #707990;
  line-height: 23px;
}
.header .search input:-ms-input-placeholder {
  color: #707990;
  line-height: 17px;
}
.header.is-elite .search input {
  background: #363a46;
  border: none;
  border-left: 1px solid #596072;
  border-right: 1px solid #596072;
  color: #acaeb3;
  height: 32px;
}
.header.is-elite .search input.is-focused {
  background-color: #fff;
  border-color: #fff;
  color: #707990;
}
.header.is-elite .search input::-webkit-input-placeholder {
  color: #acaeb3;
  line-height: 17px;
}
.header.is-elite .search input:-moz-placeholder {
  color: #acaeb3;
  line-height: 23px;
}
.header.is-elite .search input::-moz-placeholder {
  color: #acaeb3;
  line-height: 23px;
}
.header.is-elite .search input:-ms-input-placeholder {
  color: #acaeb3;
  line-height: 17px;
}
.header .search .fa-search {
  position: absolute;
  font-size: 14px;
  color: var(--link-blue);
  right: 14px;
  top: 13px;
  width: 14px;
  display: block;
  height: 13px;
  background: url('/img/icon_search.png') center right no-repeat;
  cursor: pointer;

  @media (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
    background-image: url('/img/icon_search_2x.png');
    background-size: 14px 13px;
  }
}
.header form {
  margin: 0;
  z-index: 100;
  position: relative;
}
.header .nav {
  list-style: none;
  padding: 0;
  margin: 0;
  height: 37px;
  margin-right: -20px;
}
.header .nav li {
  display: inline;
  margin-left: 10px;
}
.header .nav a {
  background: #4c5261;
  border-bottom: 1px solid #2e313c;
  color: #fff;
  display: inline-block;
  font-size: 12px;
  padding: 8px 14px;
  font-family: Lato, Verdana, Arial, Tahoma;
  text-decoration: none;
  white-space: nowrap;
  line-height: 15px;
}
.header .nav a.is-help {
  padding: 8px 10px;
}
.header .nav a.is-help:hover,
.header .nav a.is-help:active,
.header .nav a.is-help:focus {
  background: #62697d;
}
.header .nav a.is-help .fa-question-circle {
  background: url('/img/icon_question_mark.png') no-repeat;
  width: 11px;
  height: 11px;
  display: inline-block;
  top: 1px;
  line-height: 30px;
  position: relative;
  cursor: pointer;

  @media (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
    background-image: url('/img/icon_question_mark_2x.png');
    background-size: 11px 11px;
  }
}
.header .nav a.is-help .fa-alerts {
  background: no-repeat;
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg version='1.1' viewBox='0 0 11 14' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cdefs%3E%3Cpolygon id='a' points='2e-4 4e-4 10.745 4e-4 10.745 13.671 2e-4 13.671'/%3E%3C/defs%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg transform='translate(-9 -8)'%3E%3Cg transform='translate(9 8)'%3E%3Cpath d='m5.3262 1.5752c0.016 0 0.03 2e-3 0.046 2e-3s0.032-2e-3 0.048-2e-3v2e-3c0.241 3e-3 0.47 0.018 0.691 0.044-1e-3 -0.023-2e-3 -0.759-4e-3 -0.782-0.038-0.472-0.351-0.839-0.735-0.839-0.385 0-0.699 0.371-0.735 0.846-2e-3 0.02-2e-3 0.755-3e-3 0.775 0.22-0.026 0.45-0.041 0.692-0.044v-2e-3zm-0.935 11.114c0 0.542 0.439 0.982 0.981 0.982 0.543 0 0.982-0.44 0.982-0.982v-1.127h-1.963v1.127zm5.929-2.786c-0.375-0.329-1.138-0.894-1.246-2.161-0.167-1.969-0.183-3.2-0.898-4.251-0.697-1.024-1.54-1.396-2.768-1.411v-1e-3c-0.012 0-0.024 1e-3 -0.036 1e-3s-0.023-1e-3 -0.035-1e-3v1e-3c-1.227 0.015-2.071 0.387-2.768 1.411-0.715 1.051-0.731 2.282-0.898 4.251-0.108 1.267-0.87 1.832-1.246 2.161-0.353 0.312-0.425 0.829-0.425 1.175h10.745c0-0.346-0.071-0.863-0.425-1.175z' fill='%23fff' fill-rule='evenodd'/%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
  width: 11px;
  height: 11px;
  display: inline-block;
  top: 1px;
  line-height: 30px;
  position: relative;
  cursor: pointer;
}
.header .nav a.is-account:hover,
.header .nav a.is-account:active,
.header .nav a.is-account:focus {
  background: #62697d;
}
.header .nav a.is-account .fa-user {
  color: #fff;
  background: url('/img/icon_account.png') no-repeat;
  width: 11px;
  height: 11px;
  display: inline-block;
  top: 1px;
  line-height: 30px;
  position: relative;
  cursor: pointer;

  @media (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
    background-image: url('/img/icon_account_2x.png');
    background-size: 10px 11px;
  }
}
.header .nav .fa {
  padding-right: 13px;
}
.header .banner {
  height: 90px;
  margin-top: 1px;
}
.header .suggestions-box {
  background: #fff;
  color: #343843;
  font-size: 11px;
  position: absolute;
  top: 41px;
  width: 100%;
  z-index: 10;
  -webkit-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
}
.header .suggestions-box tr {
  cursor: pointer;
}
.header .suggestions-box tr.active td,
.header .suggestions-box tr:hover td {
  background: #fffce5;
  border-bottom: 1px solid #eae8d4;
}
.header .suggestions-box td {
  line-height: 28px;
  border-bottom: 1px solid #eaebec;
  font-family: Lato, Verdana, Arial, Tahoma;
}
.header .suggestions-box .ticker {
  padding-left: 10px;
  padding-right: 5px;
  width: 70px;
}
.header .suggestions-box .exchange {
  color: #7a7d84;
  font-size: 9px;
  font-style: italic;
  padding-right: 10px;
  text-transform: uppercase;
  text-align: right;
}
.header .suggestions-box .links,
.header .suggestions-box tr.links td {
  border: none;
  background: #f3f5f8;
}
.header .suggestions-box .links .search-company,
.header .suggestions-box .links .search-profile {
  color: #4488dc;
  display: block;
  text-align: center;
  font-size: 11px;
}
.header .suggestions-box .links .search-company {
  border-right: 1px solid #eaebec;
}
.header .suggestions-box .links .search-company:hover,
.header .suggestions-box .links .search-company:active,
.header .suggestions-box .links .search-company:focus,
.header .suggestions-box .links .search-profile:hover,
.header .suggestions-box .links .search-profile:active,
.header .suggestions-box .links .search-profile:focus {
  background: #fffce5;
}
.header.is-elite .fv-container {
  display: block;
}
.header.is-elite .table-row {
  display: block;
}
.header.is-elite .pull-left {
  display: block;
  width: 650px;
  max-width: none;
}
.header.is-elite .pull-right {
  display: block;
  width: auto;
}
.header.is-elite .logo {
  padding: 0;
}
.header.is-elite .search {
  padding-top: 6px;
  width: 380px;
}
.header.is-elite .search input {
  width: 380px;
}
.header.is-elite .search form {
  line-height: 32px;
}
.header.is-elite .search .fa {
  top: 11px;
}
.header.is-elite .suggestions-box {
  border-top: 1px solid #cfd0d3;
  top: 39px;
}
.header.is-elite .nav a.is-help {
  margin-top: 1px;
}
@media screen and (min-width: 1200px) {
  .header .pull-left {
    width: 30%;
  }
}
.navbar {
  width: 100%;
  height: 30px;
  border-spacing: 0;
  background-color: #4c5261;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  line-height: 30px;
  z-index: 2;

  td {
    padding: 0;
  }

  .header-container {
    white-space: nowrap;
    table-layout: auto;
  }

  #themeToggle {
    @apply flex h-7.5 w-7.5 items-center justify-center p-0 hover:bg-[#62697d];

    svg {
      @apply m-0 block;
    }
  }
}
.navbar .nav {
  float: left;
  margin: 0;
  padding: 0;
}
.navbar .nav:before,
.navbar .nav:after {
  content: ' ';
  display: table;
}
.navbar .nav:after {
  clear: both;
}
.navbar .nav:before,
.navbar .nav:after {
  content: ' ';
  display: table;
}
.navbar .nav:after {
  clear: both;
}
.navbar .nav li {
  float: left;
  list-style: none;
}

.nav-link-base,
.navbar .nav-link {
  color: #fff;
  display: block;
  font-weight: bold;
  font-size: 13px;
  padding: 0 7px;
  text-decoration: none;
  font-family: Lato, Verdana, Arial, Tahoma;
  line-height: 30px;
  white-space: nowrap;
  position: relative;
  height: 30px;
}
.navbar .nav-link.is-elite {
  background: #363a46;
}
.navbar.is-elite .nav-link.is-elite {
  background: #4c5261;
}
.navbar .nav-link.is-active,
.navbar .nav-link:hover,
.navbar .nav-link:active,
.navbar .nav-link:focus {
  background: #62697d !important;
}

.navbar .account .account-btn {
  color: #fff;
  display: block;
  line-height: 30px;
  padding: 0 11px;
  float: left;
}
.navbar .account .account-btn.is-help,
.navbar .is-help {
  font-size: 12px;
  font-weight: normal;
  height: auto;
  padding: 8px 7px 9px 7px;
  line-height: 12px;
}
.navbar .is-help .fa-question-circle {
  background: url('/img/icon_question_mark.png') no-repeat;
  width: 11px;
  height: 11px;
  display: inline-block;
  top: 1px;
  line-height: 30px;
  position: relative;
  cursor: pointer;
  padding-right: 13px;

  @media (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
    background-image: url('/img/icon_question_mark_2x.png');
    background-size: 11px 11px;
  }
}
.navbar .account .account-btn.is-sign-in,
.navbar .sign-in {
  border-left: 1px solid #444a57;
  font-size: 13px;
  font-weight: bold;
}
.navbar .account .account-btn.is-sign-up,
.navbar .sign-up {
  background: var(--link-blue);
  font-size: 13px;
  font-weight: bold;
}
.navbar .sign-up:hover,
.navbar .sign-up:focus,
.navbar .sign-up:active {
  background: #73bbff !important;
}
.navbar .account-btn.is-account {
  max-width: 220px;
  background: #565c6e;
  overflow: hidden;
  border-left: none;
  font-size: 13px;
  z-index: 10000;
  padding: 0 10px;
  height: auto;
  padding: 8px 10px 9px 10px;
  line-height: 12px;
  text-overflow: ellipsis;

  @media (max-width: 1024px) {
    & {
      max-width: 150px;
    }
  }
}
.navbar .account-btn.is-account.is-open {
  border-bottom: 2px solid var(--link-blue);
  padding: 8px 10px 7px 10px;
  height: auto;
}
.navbar .account-btn.is-account:hover,
.navbar .account-btn.is-account:active,
.navbar .account-btn.is-account:focus {
  background: #62697d;
}
.navbar .account-btn.is-account .fa-user {
  color: #fff;
  background: url('/img/icon_account.png') no-repeat;
  width: 11px;
  height: 11px;
  display: inline-block;
  top: 1px;
  line-height: 30px;
  position: relative;
  cursor: pointer;
  padding-right: 16px;

  @media (min-device-pixel-ratio: 1.3), (min-resolution: 1.3dppx) {
    background-image: url('/img/icon_account_2x.png');
    background-size: 10px 11px;
  }
}
.navbar .dropdown-menu,
.header .dropdown-menu {
  position: absolute;
  top: 31px;
  right: 0;
  border-top: 2px solid var(--link-blue);
  list-style: none;
  list-style-position: outside;
  margin: 0;
  padding: 0;
  z-index: 1000;
  min-width: 100%;
  -webkit-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
}
.header .dropdown-menu {
  top: 37px;
}
.navbar .dropdown-menu {
  top: 28px;
  line-height: 30px;
  float: none;
  border-top: none;
}
.navbar .dropdown-menu li,
.header .dropdown-menu li {
  display: block;
  margin: 0;
  padding: 0;
}
.navbar .dropdown-menu .menu-item,
.header .dropdown-menu .menu-item {
  background: #4c5161;
  border-top: 1px solid #62697d;
  border-bottom: none;
  color: #fff;
  display: block;
  font-family: Lato, Verdana, Arial, Tahoma;
  font-size: 12px;
  padding-left: 10px;
  padding-right: 10px;
  text-decoration: none;
  margin: 0;
  z-index: 10;
  position: relative;
  white-space: nowrap;
}
.navbar .dropdown-menu .menu-item:hover,
.navbar .dropdown-menu .menu-item:active,
.navbar .dropdown-menu .menu-item:focus,
.header .dropdown-menu .menu-item:hover,
.header .dropdown-menu .menu-item:active,
.header .dropdown-menu .menu-item:focus {
  background: #62697d;
}
.navbar .dropdown-menu li:first-child .menu-item,
.header .dropdown-menu li:first-child .menu-item {
  border-top: none;
}

body.is-header-sticky {
  &.is-elite {
    .navbar {
      top: 46px;
    }
  }

  .header {
    position: sticky;
    top: 0;
  }

  .navbar {
    position: sticky;
    top: 96px;
    z-index: 9999;
  }
}
